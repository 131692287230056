import conf from "./config"

export const getWorkWxLoginUrl = function (path) {
    let redirect_uri = encodeURIComponent(`${location.protocol}//${location.host}/auth`);
    let url = `https://open.work.weixin.qq.com/wwopen/sso/3rd_qrConnect?appid=${conf.CorpID}&redirect_uri=${redirect_uri}&state=${path}&usertype=member`;
    return url;
}


export const auth = function () {
    let path = location.pathname + location.search;
    let url = getWorkWxLoginUrl(path);
    location.replace(url);
}
